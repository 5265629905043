<template>
  <div class="comment-item" v-if="detail">
    <el-avatar
      size="medium"
      class="mr-10 comment-avatar"
      :src="
        detail.user.avatar
          ? viewImg(detail.user.avatar)
          : require('../../../../assets/images/userAvatar.png')
      "
    ></el-avatar>
    <div class="comment-body">
      <div class="comment-body-header">
        <div>
          <span class="comment-nickname">
            <NickName v-model="detail.user" :showAvatar="false"></NickName>
          </span>
          <span class="text-gray-6 ml-20">{{
            $utils.contrastTime(detail.create_time)
          }}</span>
          <span class="text-gray-6 ml-20" v-if="detail.is_edit > 0">
            修改过
          </span>
        </div>
        <div>
          <template v-if="user">
            <a
              href="javascript:;"
              class="mr-20"
              v-if="detail.user_id == user.id"
              @click="editComment()"
              >修改</a
            >

            <a
              href="javascript:;"
              v-if="detail.user_id == user.id || detail.for_user_id == user.id"
              @click="del()"
              >删除</a
            >
          </template>
        </div>
      </div>
      <div class="comment-content">
        <template v-if="isEditComment">
          <CommentInput
            class="mt-20"
            placeholder="发表公开回复..."
            focus
            small
            :edit_id="detail.id"
            :edit-data="detail"
            :video_id="detail.video_id"
            :comment_id="detail.comment_id"
            :at_user_id="detail.at_user_id"
            :at_user="detail.user"
            @change="editCommentPosted"
            @cancel="cancelEditComment"
          ></CommentInput>
        </template>
        <template v-else>
          <span
            class="text-info"
            v-if="detail.at_user_id && detail.at_user_id != detail.user_id"
          >
            @{{ detail.atUser.nickname }}
          </span>
          <span v-html="$utils.html2content(detail.content)"></span>
        </template>
      </div>
      <div class="d-flex align-items-center mt-10">
        <div class="pointer" @click="fav()">
          <icon type="like" size="14"></icon>
          <span class="text-gray-6 ml-10 mr-10 small">{{ detail.fav }}</span>
        </div>
        <div class="pointer" @click="unfav()">
          <icon type="unlike" size="14"></icon>
          <span class="text-gray-6 ml-10 mr-10 small">{{ detail.unfav }}</span>
        </div>
        <a href="javascript:;" class="cursor" @click="replay">回复</a>
      </div>
      <CommentInput
        class="mt-20"
        v-if="isReplayInput"
        placeholder="发表公开回复..."
        focus
        small
        :video_id="detail.video_id"
        :comment_id="comment_id ? comment_id : detail.id"
        :at_user_id="!hasReplay ? detail.user_id : 0"
        :at_user="!hasReplay ? detail.user : null"
        @change="commentPosted"
        @cancel="cancelReplay"
      ></CommentInput>
      <template v-if="hasReplay">
        <div
          class="text-info pointer"
          v-if="detail.replay > 0 && !isShowReplay"
          @click="showReplay"
        >
          查看回复({{ detail.replay }})
        </div>
        <div v-loading="loading" v-if="isShowReplay">
          <ListItem
            v-for="(item, i) in lists"
            :key="item.id"
            v-model="lists[i]"
            :hasReplay="false"
            :comment_id="detail.id"
            @replayChanged="commentPosted"
            @deleted="commentDeleted"
          >
          </ListItem>
          <div
            class="text-info pointer text-center mt-10"
            v-if="params.pages > 1 && params.page < params.pages"
            @click="loadMore"
          >
            查看更多回复
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CommentInput from "./Input.vue";
export default {
  name: "ListItem",
  components: {
    CommentInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    //是否包含回复
    hasReplay: {
      type: Boolean,
      default: true,
    },
    comment_id: {
      //回复评论id
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
      lists: [],

      params: {
        video_id: 0,
        for_user_id: 0,
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        pages: 1,
      },
      isReplayInput: false, //是打开回复窗口
      isShowReplay: false, //是否显示回复
      isEditComment: false, //是否编辑评论
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.detail = val;
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.detail = this.value;
  },
  methods: {
    replay() {
      this.isReplayInput = true;
      console.log(" this.isReplayInput :", this.isReplayInput);
    },
    cancelReplay() {
      this.isReplayInput = false;
    },
    commentPosted(item) {
      this.detail.replay++;
      /*     if (this.isShowReplay) {
        this.params.total++;
        this.lists.unshift(item);
      }
      */
      this.$emit("replayChanged", item);
      if (this.isShowReplay) {
        this.getList();
      }
    },
    showReplay() {
      this.isShowReplay = true;
      this.getList();
    },
    getList(page) {
      page = page || 1;
      this.params.comment_id = this.detail.id;
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.video.Comment.lists(this.params)
        .then((res) => {
          this.loading = false;
          if (page == 1) {
            this.lists = res.data.lists;
          } else {
            this.lists = this.lists.concat(res.data.lists);
          }
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    //加载更多
    loadMore() {
      let page = this.params.page;
      page++;

      if (page <= this.params.pages) {
        this.getList(page);
      }
    },
    //评论修改
    editComment() {
      this.isEditComment = true;
    },
    //评论修改完毕
    editCommentPosted(res) {
      console.log("res:", res);
      this.detail = res;
      this.isEditComment = false;
    },
    //取消编辑评论
    cancelEditComment() {
      this.isEditComment = false;
    },
    //点赞
    fav() {
      console.log("f");
      this.$api.video.Comment.fav({
        video_id: this.detail.video_id,
        comment_id: this.detail.id,
      })
        .then((res) => {
          this.detail.fav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点踩
    unfav() {
      this.$api.video.Comment.unfav({
        video_id: this.detail.video_id,
        comment_id: this.detail.id,
      })
        .then((res) => {
          this.detail.unfav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del() {
      this.$utils.confirm(
        `确定要删除吗？本才做将删除该评论以及其下相关回复且不可逆，请谨慎操作"`,
        () => {
          let delMethod = this.$api.video.Comment.del;
          let params = {
            video_id: this.detail.video_id,
            id: this.detail.id,
          };
          if (this.detail.user_id != this.user.id) {
            delMethod = this.$api.video.Comment.delByFor;
            params = {
              id: this.detail.id,
            };
          }
          delMethod(params)
            .then((res) => {
              this.$emit("deleted", this.detail.id);
            })
            .catch((err) => {
              console.log(err);
            });
        },
        () => {}
      );
    },
    commentDeleted() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.comment-body {
  width: calc(100% - 46px);
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.comment-content {
  margin-top: 10px;
  width: calc(100% - 46px);
}
.comment-content,
.comment-content * {
  word-break: break-all;
  word-wrap: break-word;
}
.comment-nickname {
  color: #000;
  font-weight: 600;
}
</style>