<template>
  <div>
    <h5>{{ totalAll }}条评论</h5>
    <CommentInput
      class="mt-20"
      :video_id="video_id"
      @change="commentPosted"
    ></CommentInput>
    <div v-loading="loading">
      <ListItem
        v-for="(item, i) in lists"
        :key="item.id"
        v-model="lists[i]"
        @deleted="commentDeleted"
      >
      </ListItem>
      <div
        class="text-info pointer text-center mt-10"
        v-if="params.pages > 1 && params.page < params.pages"
        @click="loadMore"
      >
        查看更多评论
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import CommentInput from "./Input.vue";
export default {
  name: "List",
  components: {
    CommentInput,
    ListItem,
  },
  props: {
    video_id: {
      type: Number,
      default: 0,
    },
    for_user_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,

      lists: [],
      totalAll: 0,
      params: {
        video_id: 0,
        for_user_id: 0,
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        pages: 1,
      },
    };
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {
    // 更新评论总数量
    this.$bus.$on("commentTotalChange", (num) => {
      this.totalAll += num;
    });
  },
  methods: {
    getList(page) {
      page = page || 1;
      this.params.video_id = this.$utils.toInt(this.video_id);
      this.params.for_user_id = this.$utils.toInt(this.for_user_id);
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.video.Comment.lists(this.params)
        .then((res) => {
          this.loading = false;
          if (page == 1) {
            this.lists = res.data.lists;
          } else {
            this.lists = this.lists.concat(res.data.lists);
          }
          this.totalAll = res.data.totalAll;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //在列表最前面插入新评论
    commentPosted(item) {
      /*   this.params.total++;
      this.lists.unshift(item); */
      this.getList();
    },
    commentDeleted() {
      this.getList();
    },
    //加载更多
    loadMore() {
      let page = this.params.page;
      page++;

      if (page <= this.params.pages) {
        this.getList(page);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>