<template>
  <div class="layout">
    <Header :hasSearch="false" hasShadow></Header>
    <div class="layout-container-fuild" v-loading="loading">
      <div class="container mt-20 mb-20 pb-20">
        <template v-if="detail">
          <div class="row">
            <div class="col-md-16" ref="videoContainer">
              <!-- {{isAuth}} -->
              <div v-if="isAuth === 0">
                <video-player
                  v-if="detail.video"
                  class="video-player-box"
                  ref="videoPlayer"
                  :options="playerOptions"
                  :playsinline="true"
                >
                </video-player>
              </div>
                <!-- {{detail.video}} -->
                <video
                  id="myVideo"
                  class="video-js vjs-big-play-centered vjs-fluid vjs-fade-out"
                  style="object-fit: fill"
                  webkit-playsinline="true"
                  playsinline="true"
                >
                <source src="" type="application/x-mpegURL" />
                </video>
              <div
                class="d-flex justify-content-center flex-column"
                slot="header"
              >
                <h1 class="text-black">{{ detail.title }}</h1>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    text-gray-6
                  "
                >
                  <div>
                    {{ detail.createTime }}
                    <span class="ml-10">
                      {{ $utils.contrastTime(detail.create_time) }}</span
                    >
                    <span class="ml-10">{{
                      detail.is_original ? "转载" : "原创"
                    }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <div
                      class="pointer d-flex align-items-center"
                      @click="fav()"
                    >
                      <icon type="like" size="14"></icon>
                      <span class="text-gray-6 ml-10 mr-10 small">{{
                        detail.fav
                      }}</span>
                    </div>
                    <div
                      class="pointer d-flex align-items-center"
                      @click="unfav()"
                    >
                      <icon type="unlike" size="14"></icon>
                      <span class="text-gray-6 ml-10 small">{{
                        detail.unfav
                      }}</span>
                    </div>
                    <div class="pointer ml-10" @click="favorite()">
                      <el-tooltip effect="dark" content="收藏" placement="top">
                        <icon type="star" size="14"></icon>
                      </el-tooltip>
                    </div>
                    <div
                      class="pointer ml-10"
                      v-clipboard:copy="copyConetent"
                      v-clipboard:success="onCopy"
                    >
                      <el-tooltip effect="dark" content="分享" placement="top">
                        <icon type="share" size="14"></icon>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-start mt-20"
                v-if="detail && detail.user"
              >
                <el-avatar
                  size="medium"
                  class="mr-10"
                  :src="
                    detail.user && detail.user.avatar
                      ? viewImg(detail.user.avatar)
                      : require('../../assets/images/userAvatar.png')
                  "
                ></el-avatar>
                <div class="w-100">
                  <div style="font-weight: bold">
                    <NickName
                      v-model="detail.user"
                      :showAvatar="false"
                    ></NickName>
                  </div>
                  <div class="text-gray-6">
                    {{ detail.description }}
                  </div>
                </div>
              </div>
              <div class="text-gray-6"></div>

              <CommentList
                ref="commentList"
                class="mt-20"
                :video_id="id"
              ></CommentList>
            </div>
            <div class="col-md-8" ref="rightSide">
              <router-link
                v-for="item in relatedLists"
                :key="'item' + item.id"
                :to="{ path: '/video/detail/' + item.id }"
                class="block flex-fill"
              >
                <img
                  v-if="item.thumb"
                  :src="viewThumb(item.thumbData.thumb, 'medium')"
                  class="thumb"
                />

                <div class="block-body">
                  <div class="block-body-title">
                    {{ item.title }}
                  </div>

                  <div
                    class="
                      block-body-desc
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <NickName v-model="item.user"></NickName>

                    <span> {{ $utils.contrastTime(item.create_time) }}</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="p-50 text-center border-1">内容不存在</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

import Header from "../layout/Header.vue";

import CommentList from "./components/comment/List.vue";

Vue.prototype.$video = Video
export default {
  name: "Detail",
  components: { Header, CommentList },
  data() {
    return {
      loading: false,
      id: 0,
      detail: {},
      isAuth: 0,
      isShow: false,
      myVideo:null,
      testUrl: "",
      playerOptions: {
        // videojs options
        width: 1280,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          /* {
            type: "video/mp4", //视频类型
            src: "" //播放地址
          } */
        ],
        poster: "", //封面
      },
      relatedLists: [], //相关视频
      copyConetent: "", //复制内容
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.id = this.$utils.toInt(this.$route.params.id);

        this.getDetail();
      },
      deep: true,
    },
  },
  metaInfo() {
    return {
      title: this.detail.title || this.$route.meta.title,
    };
  },
  created() {
    this.id = this.$utils.toInt(this.$route.params.id);
    this.getDetail();
  },
  mounted() {
    //this.initVideo();
  },
  updated() {
    if(this.isAuth === 1){
      this.isShow = true;
      this.initVideo();
      this.myVideo.reset(); //重置 video
      this.myVideo.removeClass('vjs-fade-out');
      console.log("video url=="+this.testUrl);
      this.myVideo.src([
          {
            type: 'application/x-mpegURL',
            src: "/"+this.testUrl
          },
      ]);
      this.myVideo.load();
      //this.myVideo.play();
      
      // this.myVideo.on("loadedmetadata",function(){
      //   console.log("视频源数据加载完成");
      //   if(this.isShow == undefined){
      //     this.myVideo.controlBar.addClass('vjs-fade-out'); 
      //   }else{
      //     this.myVideo.controlBar.removeClass('vjs-fade-out');
      //   }
      //   //console.log("this.isShow=="+this.isShow);
      // })
      
    }
    //this.changeVideo();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.$api.video.Video.detail({ id: this.id })
        .then((res) => {
          this.isAuth = res.data.is_auth;
          this.loading = false;
          this.detail = res.data;
          this.testUrl = res.data.video;
          this.playerOptions.sources = [
            {
              type: "video/mp4", //视频类型
              src: this.viewImg(res.data.video), //播放地址
            },
          ];

          this.playerOptions.poster = this.viewImg(res.data.thumbData.path);
          this.relatedList();
          this.setVideoWidth();
          this.copyConetent = this.detail.title + "\n" + window.location.href;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    relatedList() {
      this.loading = true;
      this.$api.video.Video.lists({
        related: 1,
        category_id: this.detail.category_id,
        user_id: this.detail.user_id,
      })
        .then((res) => {
          this.loading = false;
          this.relatedLists = res.data.lists;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setVideoWidth() {
      if ("videoContainer" in this.$refs) {
        this.playerOptions.width = this.$refs.videoContainer.clientWidth - 20; //视频窗口宽度
        const that = this;
        window.onresize = function () {
          // 定义窗口大小变更通知事件
          that.playerOptions.width = that.$refs.videoContainer.clientWidth - 20; //视频窗口宽度
        };
      }
    },
    initVideo () {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
          //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
          controls: true,
          //自动播放属性,muted:静音播放
          //autoplay: "muted",
          //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: "auto",
          //设置视频播放器的显示宽度（以像素为单位）
          width: "800px",
          //设置视频播放器的显示高度（以像素为单位）
          height: "400px"
      });
      this.myVideo = myPlayer;
    },
    changeVideo(){
      console.log('changeVideo');
      
      this.myVideo.reset(); //重置 video
      this.myVideo.src([
        {
          type: 'application/x-mpegURL',
          src:this.testUrl
        },
      ]);
      this.myVideo.load();
      //this.myVideo.play();
    },

    //点赞
    fav() {
      this.$api.video.Video.fav({
        id: this.detail.id,
      })
        .then((res) => {
          this.detail.fav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点踩
    unfav() {
      this.$api.video.Video.unfav({
        id: this.detail.id,
      })
        .then((res) => {
          this.detail.unfav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //收藏
    favorite() {
      this.$api.video.Video.favorite({
        id: this.detail.id,
      })
        .then((res) => {
          this.$utils.success(res.data.add > 0 ? "已收藏" : "已移出收藏夹");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCopy() {
      this.$utils.success("分享内容以及链接已复制到剪切板");
    },
  },
};
</script>

<style lang="scss" scoped>
  .video-js.vjs-fluid,
  .video-js.vjs-16-9,
  .video-js.vjs-4-3{ /* 视频占满容器高度 */
    height: 210px;
    background-color: #161616;
  }
  .vjs-fade-out {
    display: block;
    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 1.5s, opacity 1.5s;
      -moz-transition: visibility 1.5s, opacity 1.5s;
        -ms-transition: visibility 1.5s, opacity 1.5s;
        -o-transition: visibility 1.5s, opacity 1.5s;
            transition: visibility 1.5s, opacity 1.5s;

    /* Wait a moment before fading out the control bar */
    -webkit-transition-delay: 2s;
      -moz-transition-delay: 2s;
        -ms-transition-delay: 2s;
        -o-transition-delay: 2s;
            transition-delay: 2s;
  }
</style>