<template>
  <div>
    <div class="comment-input">
      <el-avatar
        size="medium"
        class="mr-10"
        :class="{ 'small-avatar': small }"
        :src="
          user && user.avatar
            ? viewImg(user.avatar)
            : require('../../../../assets/images/userAvatar.png')
        "
      ></el-avatar>
      <div
        class="comment-input-body"
        :class="{ 'textarea-focus': isTextareaFocus }"
        ref="commentInput"
        @click="textareaFocus"
      >
        <div
          v-if="user && at_user_id && at_user_id != user.id"
          class="comment-input-at-user text-info"
        >
          @<NickName v-model="at_user" :showAvatar="false"></NickName>
        </div>
        <el-input
          v-model="content"
          ref="input"
          type="textarea"
          autosize
          :autofocus="focus"
          :placeholder="placeholder"
          @focus="isTextareaFocus = true"
          @blur="isTextareaFocus = false"
        >
        </el-input>
      </div>
    </div>
    <div class="text-right mt-10" v-show="showBtn">
      <el-button class="border-0" type="default" size="small" @click="cancel"
        >取消</el-button
      >
      <el-button type="default" size="small" @click="onSubmit">{{
        edit_id ? "保存" : "评论"
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    edit_id: {
      //编辑评论id
      type: Number,
      default: 0,
    },
    editData: {
      //编辑评论数据
      type: Object,
      default: () => {},
    },
    video_id: {
      //视频id
      type: Number,
      default: 0,
    },
    at_user_id: {
      //@用户id
      type: Number,
      default: 0,
    },
    at_user: {
      //@用户
      type: [String, Object],
      default: null,
    },
    comment_id: {
      //回复评论id
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "发表公开评论...",
    },
    focus: {
      //聚焦输入框
      type: Boolean,
      default: false,
    },
    small: {
      //小尺寸
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: "",
      isTextareaFocus: false,
      showBtn: false,
    };
  },
  watch: {
    editData(val) {
      if (val) {
        this.content = val.content;
      }
    },
    focus(val) {
      if (val) {
        this.focusInput();
      }
    },
  },
  mounted() {
    if (this.editData) {
      this.content = this.editData.content;
    }
    if (this.focus) {
      this.focusInput();
    }
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
      this.$refs.commentInput.click();
    },
    textareaFocus() {
      if (!this.user) {
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$route.fullPath,
          },
        });
        return;
      }
      this.showBtn = true;
    },
    cancel() {
      this.content = "";
      this.showBtn = false;
      this.$emit("cancel");
    },
    onSubmit() {
      this.content = this.$utils.trim(this.content);
      if (this.$utils.isEmpty(this.content)) {
        this.$utils.error("请输入内容");
        return;
      }
      this.$api.video.Comment.save({
        id: this.edit_id,
        video_id: this.video_id,
        at_user_id: this.at_user_id,
        comment_id: this.comment_id,
        content: this.content,
      })
        .then((res) => {
          if (this.edit_id) {
            res.data.content = this.content;
            res.data.is_edit = 1;
          }
          this.$emit("input", res.data);
          this.$emit("change", res.data);
          this.$utils.success("评论发布出去了");
          this.cancel();
          // 更新评论总数量
          this.$bus.$emit("commentTotalChange", 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes line {
  from {
    left: 50%;
    width: 0;
  }
  to {
    width: 100%;
    left: 0;
  }
}
.comment-input {
  display: flex;
  justify-content: flex-start;
}

.comment-input-body {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #666;
}
.comment-input-at-user {
  white-space: nowrap;
  line-height: 30px;
}

.textarea-focus {
  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: "";
    background: #222;
    animation: line 0.5s linear infinite;
    animation-iteration-count: 1;
  }
}

::v-deep .el-textarea__inner {
  position: relative;
  resize: none;
  border: 0;
  border-radius: 0;
  &:after {
    display: block;
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: 2px;
    content: "";
    background: #222;
    animation: line 1s linear infinite;
    animation-iteration-count: 1;
  }
}

.small-avatar {
  width: 34px;
  height: 34px;
}
</style>